import * as React from "react";
import { useState, useEffect } from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const IndexPage = () => {

    const [enviado, setenviado] = useState(false)
    const [detbot, setdetbot] = useState(false)
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [token, setToken] = useState('')

    useEffect(() => {
        setdetbot(false);
        let timer = setTimeout(() => {
            setenviado(false)
        }, 5000)
        return () => {
            clearTimeout(timer);
        }
    }, [enviado])

    useEffect(() => {
        setenviado(false);
        let timer = setTimeout(() => {
            setdetbot(false)
        }, 5000)
        return () => {
            clearTimeout(timer);
        }
    }, [detbot])




    return (
        <TemplatePage1
            title={"Contacto"}
            content={(
                <Wrapper >
                    <Monitor>
                        <Screen>
                            <FormWrapper>
                                <h1 className={"text-white"}>
                                    ¡Comunícate con nuestro equipo!
                                </h1>
                                <Formik
                                    initialValues={{
                                        nombre: '',
                                        apellidos: '',
                                        pais: '',
                                        correo: '',
                                        mensaje: '',
                                        suscribir: '',
                                    }}

                                    validate={(valores) => {
                                        let errores = {};

                                        //valida nombre
                                        if (!valores.nombre) {
                                            errores.nombre = 'Por favor ingresa un nombre'
                                        } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.nombre)) {
                                            errores.nombre = 'El nombre solo puede contener letras y espacios'
                                        }

                                        //validar apellidos
                                        if (!valores.apellidos) {
                                            errores.apellidos = 'Por favor ingrese sus apellidos'
                                        } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.apellidos)) {
                                            errores.apellidos = 'El apellido solo puede contener letras y espacios'
                                        }

                                        //validar pais
                                        if (!valores.pais) {
                                            errores.pais = 'Por favor ingrese su pais'
                                        } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.pais)) {
                                            errores.pais = 'El país solo puede contener letras y espacios'
                                        }

                                        //validar correo
                                        if (!valores.correo) {
                                            errores.correo = 'Por favor ingrese su correo'
                                        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.correo)) {
                                            errores.correo = 'El correo solo puede contener ciertos caracteres'
                                        }

                                        //validar correo
                                        if (!valores.mensaje) {
                                            errores.mensaje = 'Por favor ingrese su mensaje'
                                        }

                                        return errores;
                                    }}

                                    onSubmit={async (valores, { resetForm }) => {

                                        if (!executeRecaptcha) {
                                            return
                                        };



                                        //console.log('Se envio su mensaje');
                                        const result = await executeRecaptcha('homepage');
                                        setToken(result);
                                        if (token === "") {
                                            setdetbot(true);
                                        } else {
                                            setenviado(true);
                                            resetForm();
                                            const data = JSON.stringify({ valores, token });
                                            //console.log("data env:" + data);

                                            fetch('../conn2/registermessage.php', {
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json, text/plain, */*',
                                                    'Content-type': 'application/json'
                                                },
                                                body: data //--> this contains your data
                                            })
                                                .then(res => {
                                                    //console.log("res :"+JSON.stringify(res))
                                                })
                                                .then(data => {
                                                    //console.log("data : "+data) //--> dynamically set your notification state via the server
                                                }).catch(err => console.log("err: " + err))
                                        }

                                    }}

                                >

                                    {({ errors }) => (


                                        <StyledForm>
                                            <QuestionSection>
                                                <label htmlFor="nombre"> Nombres:</label>
                                                <InputSection>
                                                    <Field
                                                        type="text"
                                                        name="nombre"
                                                    />
                                                    <ErrorMessage
                                                        name="nombre"
                                                        component={() => (<span>{errors.nombre}</span>)}
                                                    />
                                                </InputSection>
                                            </QuestionSection>
                                            <QuestionSection>
                                                <label htmlFor="apellidos"> Apellidos:</label>
                                                <InputSection>
                                                    <Field
                                                        type="text"
                                                        name="apellidos"
                                                    />
                                                    <ErrorMessage
                                                        name="apellidos"
                                                        component={() => (<span>{errors.apellidos}</span>)}
                                                    />
                                                </InputSection>
                                            </QuestionSection>
                                            <QuestionSection>
                                                <label htmlFor="pais"> Pais:</label>
                                                <InputSection>
                                                    <Field
                                                        type="text"
                                                        name="pais"
                                                    />
                                                    <ErrorMessage
                                                        name="pais"
                                                        component={() => (<span>{errors.pais}</span>)}
                                                    />
                                                </InputSection>
                                            </QuestionSection>
                                            <QuestionSection>
                                                <label htmlFor="correo"> Correo:</label>
                                                <InputSection>
                                                    <Field
                                                        type="text"
                                                        name="correo"
                                                    />
                                                    <ErrorMessage
                                                        name="correo"
                                                        component={() => (<span>{errors.correo}</span>)}
                                                    />
                                                </InputSection>
                                            </QuestionSection>
                                            <QuestionSection>
                                                <label htmlFor="mensaje"> Mensaje:</label>
                                                <InputSection>
                                                    <Field
                                                        type="text"
                                                        name="mensaje"
                                                        as="textarea"
                                                    />
                                                    <ErrorMessage
                                                        name="mensaje"
                                                        component={() => (<span>{errors.mensaje}</span>)}
                                                    />
                                                </InputSection>
                                            </QuestionSection>
                                            <QuestionSection>
                                                <label htmlFor="suscribir"> Deseas suscribirte a nuestras noticias:</label>
                                                <InputSection>
                                                    <div className="flex flex-row items-center ml-5" role="group" aria-labelledby="my-radio-group">
                                                        <label className="flex flex-row items-center ml-8" style={{ "minwidth": "50px" }}>
                                                            Si
                                                            <Field className="ml-2" type="radio" name="suscribir" value="1" />

                                                        </label>
                                                        <label className="flex flex-row items-center ml-8" style={{ "minwidth": "50px" }}>
                                                            No
                                                            <Field className="ml-2" type="radio" name="suscribir" value="0" />

                                                        </label>
                                                    </div>
                                                </InputSection>
                                            </QuestionSection>
                                            <button type="submit">
                                                Enviar
                                            </button >
                                            {enviado && <span>Le responderemos tan pronto sea posible</span>}
                                            {detbot && <span>Queremos confirmar que no es un robot. Por favor, intente una vez más.</span>}
                                        </StyledForm>

                                    )}


                                </Formik>



                            </FormWrapper>
                        </Screen>
                    </Monitor>
                </Wrapper >
            )}
        />
    )
};

export default IndexPage;

const StyledForm = styled(Form)`
width:100%;
`

const Wrapper = styled.div`
    margin: 1rem auto ;
    width:100%;
    display: flex;
	flex-direction: column;
	justify-items: flex-start;
	align-items: center;
`;

const Monitor = styled.div`
padding : 0.5rem;
width: 100%;
background-color:black;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;







`;

const Screen = styled.div`
width: 100%;
height: 100%;
background-color:var(--tema4);
label{
    color:white;
}
span{
    color:var(--color2);
}

`

const FormWrapper = styled.div`
padding: 0.5rem;
width: 100%;
margin: auto;

span{
            text-align: center;
            font-size: 0.9rem;
            font-weight: 500;
            
        }

h1{
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

form{
        display: flex;
        flex-direction: column;

      

    width: 100%;
    
    button{
        padding: 0.5rem 1rem;
        background-color: var(--color2);
        color: var(--white);
        margin: 0.5rem auto;
    }
}
`;

const QuestionSection = styled.div`
width:90%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 20px 10px;
@media only screen and (min-width:640px){
    flex-direction: row;
    flex-wrap: nowrap;
    label{
        width:100px;
    }
}
`;

const InputSection = styled.div`
@media only screen and (min-width:768px){
    width: 80%;
}
width: 100%;
textarea,input{
    width:100%;
    color: var(--tema4);
    padding:0.5rem;
}
input{
    height:30px;
}
textarea{
    height:120px;
}
`




